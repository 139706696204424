import CrewAPI from '@/api/crew.api';
import DronAPI from '@/api/dron.api';
import CarAPI from '@/api/car.api';
import StaffAPI from '@/api/staff.api';
import OrderAPI from '@/api/order.api';
import { ref } from 'vue';
import useMessage from '@/composables/useMessage';
import { IStatistics } from '@/interfaces/models/order';

const { requestError } = useMessage();
const loading = ref(true);
const crewCount = ref(0);
const dronesCount = ref(0);
const staffCount = ref(0);
const carsCount = ref(0);
const statistics = ref<IStatistics>();
const paginationParams = { page: 1, size: 1 };

function getCrews() {
  return CrewAPI.get(paginationParams).then((crews) => {
    crewCount.value = crews.total;
    return crews.total;
  });
}

function getStatistics() {
  return OrderAPI.statistics().then((_statistics) => {
    statistics.value = _statistics;
  });
}

function getDrones() {
  return DronAPI.get(paginationParams).then((drones) => {
    dronesCount.value = drones.total;
  });
}

function getStaff() {
  return StaffAPI.get(paginationParams).then((staff) => {
    staffCount.value = staff.total;
  });
}

function getCars() {
  return CarAPI.get(paginationParams).then((cars) => {
    carsCount.value = cars.total;
  });
}

function getDashboardData(isClient = false) {
  loading.value = true;

  return (
    (isClient
      ? getStatistics()
      : getCrews().then<any>((total) => {
          if (!total) return Promise.resolve();
          return Promise.all([
            getStatistics(),
            getDrones(),
            getStaff(),
            getCars(),
          ]);
        })) as Promise<any>
  )
    .catch(requestError)
    .finally(() => {
      loading.value = false;
    });
}

export default () => ({
  loading,
  crewCount,
  dronesCount,
  staffCount,
  carsCount,
  statistics,
  getDashboardData,
  getStatistics,
});
